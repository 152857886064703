@font-face {
  font-family: 'icomoon';
  src:  url('~assets/fonts/Icons/icomoon.eot?4pbg7w');
  src:  url('~assets/fonts/Icons/icomoon.eot?4pbg7w#iefix') format('embedded-opentype'),
  url('~assets/fonts/Icons/icomoon.ttf?4pbg7w') format('truetype'),
  url('~assets/fonts/Icons/icomoon.woff?4pbg7w') format('woff'),
  url('~assets/fonts/Icons/icomoon.svg?4pbg7w#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-cashback:before {
  content: "\e900";
}
.icon-children:before {
  content: "\e901";
}
.icon-facebook:before {
  content: "\e902";
}
.icon-instagram:before {
  content: "\e903";
}
.icon-installment:before {
  content: "\e904";
}
.icon-linkedin:before {
  content: "\e905";
}
.icon-pinterest:before {
  content: "\e906";
}
.icon-ssl:before {
  content: "\e907";
}
.icon-tripadvisor:before {
  content: "\e908";
}
.icon-twitter:before {
  content: "\e909";
}
.icon-win:before {
  content: "\e90a";
}
.icon-youtube:before {
  content: "\e90b";
}
.icon-whatsapp:before {
  content: "\ea93";
}
.icon-google:before {
  content: "\ea88";
}
.icon-appleinc:before {
  content: "\eabe";
}
