@mixin loading($flag: false, $custom: false) {
    width: 100%;
    @if $flag {
        @if $custom {
            height: 250px;
        } @else {
            height: 100%;
        }
    } @else {
        display: inline;
        border-radius: 2px;
    }

    position: relative;
    background-color: #e2e2e2;
    color: #e2e2e2;
    border: none;
    &::after {
        display: block;
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        transform: translateX(-100%);
        background: -webkit-gradient(
            linear,
            left top,
            right top,
            from(transparent),
            color-stop(rgba(255, 255, 255, 0.2)),
            to(transparent)
        );

        background: linear-gradient(
            90deg,
            transparent,
            rgba(255, 255, 255, 0.2),
            transparent
        );

        /* Adding animation */
        animation: loading 0.8s infinite;
    }
    img {
        display: none;
    }
}

@mixin content-box {
    min-height: 350px;

    display: flex;
    flex-direction: column;
    justify-content: space-around;

    h6 {
        text-transform: uppercase;
    }
    p {
        font-size: .875rem;
        margin-top: 1rem;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        margin-bottom: 0;
        line-height: 1.25rem;
        max-height: 3.75rem;
    }
    .default {
        margin-bottom: 1rem;
        border-bottom: solid 1px #dee2e6;
        @media only screen and (max-device-width: 800px) {
            margin-bottom: 0;
            border-bottom: none;
        }
    }
    button {
        width: 100%;
        margin-top: 1rem;
        padding: 0.5rem 1rem;
        border: solid 2px;
        color: var(--theme-client-color);
        background: transparent;

        transition: all 0.3s ease;

        &.default-button {
            width: 50%;
            margin: 0 auto;
            @media only screen and (max-device-width: 800px) {
                width: 100%;
                margin-top: 1rem;
            }
        }

        &:hover {
            background: var(--theme-client-color);
            color: white;
        }
    }
    .price {
        .description {
            font-size: 0.85rem;
            color: #484848;
        }
        .value {
            color: var(--theme-client-color);
        }

        &.package {
            padding: 0 0 0.5rem 0;
            border-bottom: solid 1px #dee2e6;
            display: flex;
            align-items: center;
            justify-content: space-between;

            &.no-border {
                border: none;
            }

            .old {
                color: #f44336;
                font-size: 0.85rem;
                text-decoration: line-through;
            }

            .total {
                color: #4caf50;
            }
        }
    }
}

@mixin genericPagesList {
    figure {
        max-height: 160px;
        overflow: hidden;
    }
    a {
        text-decoration: none;
    }
    .box-page {
        border: solid 1px #dedede;
        padding: 1rem;
        min-height: 200px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        h4 {
            font-weight: normal;
            font-size: 1rem;
            color: #484848;
            margin-bottom: 0;
        }
        p {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
        }
        .box-button {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            button {
                //width: 100%;
                margin-top: 1rem;
                padding: 0.5rem 1rem;
                border: solid 2px;
                color: var(--theme-client-color);
                background: transparent;

                transition: all 0.3s ease;
                &:hover {
                    background: var(--theme-client-color);
                    color: white;
                }
            }
        }
    }
}

@mixin contentPackagesDefault {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1.25rem;
    border: solid 1px #dee2e6;
    text-align: center;
    @media only screen and (max-device-width: 992px) {
        text-align: left;
        padding: 1rem;
        margin-bottom: 1rem;

        max-height: 100%;
    }

    .content-box {
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        //border-bottom: solid 1px #dee2e6;
        @media only screen and (max-device-width: 992px) {
            margin-bottom: 0;
            border-bottom: none;
        }

        h6 {
            text-transform: uppercase;
            font-size: 1rem;
            margin-bottom: 0;
        }

        span {
            font-size: .85rem;
            color: rgba(72, 72, 72, .85);
            font-weight: 400;
        }

        p {
            margin: 1rem;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            height: 3rem;
            line-height: 1.5rem;


            @media only screen and (max-device-width: 992px) {
                margin: 1rem 0;
                -webkit-line-clamp: 2;
            }
        }

    }

    .footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        grid-gap: 1rem;

        padding-top: 1rem;

        @media only screen and (max-device-width: 992px) {
            flex-wrap: wrap;
        }

        .prices {
            text-align: left;

            .lower-price {
                font-size: .85rem;
                color: var(--theme-client-red);
                text-decoration: line-through;
            }

            .price {
                font-size: 1rem;
                color: var(--theme-client-green);
            }
        }

        .payment {
            color: rgba(72,72,72,.85);
            @media only screen and (max-device-width: 992px) {
                text-align: right;
            }
            div {
                color: var(--theme-client-green);
            }

        }

        a {
            @media only screen and (max-device-width: 992px) {
                flex: 0 0 100%;
            }
            button {
                width: 100%;
                padding: .5rem 2rem;
                border: solid 2px var(--theme-client-color);
                color: var(--theme-client-color);
                background: transparent;

                transition: all .3s ease;

                &:hover {
                    background: var(--theme-client-color);
                    color: white;
                }
            }
        }
    }
}

@mixin button {
    color: white;
    padding: 0.5rem 1rem;
    border: none;
    width: 100%;
    border-radius: 4px;
}

@mixin popover($color) {
    position: absolute;
    top: 32px;
    padding: 0.75rem;
    background: white;
    border-radius: 4px;
    border: solid 1px $color;
    right: -38px;
    z-index: 1;
    min-width: 212px !important;
    word-break: break-word;

    &:before {
        content: "";
        width: 0;
        height: 0;
        border-width: 0 10px 10px 10px;
        border-color: transparent transparent #ffffff transparent;
        border-style: solid;

        top: -10px;
        left: calc(78% - 10px);
        position: absolute;

        filter: drop-shadow(0px -1px 0px $color);
    }
    p {
        color: $color;
        font-size: 0.8rem;
        font-weight: 400;
        margin-bottom: 0.5rem;
        &:last-child {
            margin-bottom: 0;
        }
    }
}

@mixin pricesFinish {
    .prices {
        & .price-item + .price-item {
            border-left: 1px solid #e9e9e9;

            &.ame {
                padding-left: 1.25rem;
            }

            &.pix {
                border-left: none;
            }

            @media (max-width: 768px) {
                border-left: none;
            }
        }

        .price-item {
            font-size: 1rem;
            figure {
                display: flex;
                img {
                    margin: 0 auto;
                }
            }

            @media (max-width: 768px) {
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-direction: column;
                text-align: center;
                font-size: .85rem;
            }

            span {
                display: block;

                &.info {
                    color: #484848;
                    font-size: .8rem;
                    display: inline-flex;
                    align-items: center;
                    position: relative;

                    i {
                        font-size: 1rem;
                        color: #ff9800;
                        margin-left: .25rem;
                        cursor: pointer;
                    }
                }
                &.price {
                    color: #4db911;
                    font-size: 1.25rem;
                    @media (max-width: 768px) {
                        font-size: 1rem;
                    }
                }
            }

            .qrcode {
                display: flex;
                align-items: center;
                grid-gap: 0;
                justify-content: center;
                flex-direction: column;
                div {
                    text-align: left;
                    width: 100%;
                }

                @media (max-width: 768px) {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    grid-gap: .5rem;
                }

                figure {
                    max-width: 300px;
                }
                strong {
                    display: block;
                };
            }

            button {
                padding: .5rem 1rem;
                border-radius: 4px;
                border: none;
                background: var(--theme-client-green);
                color: white;
                text-align: center;
                margin-bottom: 1rem;

                &.ame {
                    background: #ee455b;
                }
            }
        }

        .sale-info {
            display: flex;
            justify-content: space-between;
            gap: 1rem;
            flex-direction: column;

            &.ame {
                flex-direction: row;
            }

            &.deposit {
                justify-content: center;
                flex-direction: row;
            }

            @media (max-width: 768px) {
                flex-direction: row;
            }
        }

    }
}

@mixin paymentCheckout {
    border: 1px solid #e9e9e9;

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1.5rem;

        @media (max-width: 768px) {
            display: flex;
            flex-direction: column;
        }

        span {
            font-size: 1.25rem;
        }

        &.finish {
            color: #4caf50;
            font-size: 1rem;
            justify-content: center;

            padding: 1rem;
            background: #f5f5f5;
            .pay {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                @media (max-width: 768px) {
                    flex-direction: column;
                    grid-gap: 0.25rem;
                }
            }
        }

    }

    .content {
        padding: 1.5rem;
        @media (max-width: 768px) {
            border: solid 1px #dedede;
            border-top: none;
        }

        .title {
            span {
                display: block;
                font-size: 1.25rem;
                text-align: center;
            }
        }

        @include pricesFinish;
    }
}

@mixin accommodationsCheckout {
    border: 1px solid #f4f4f4;

    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-gap: 1rem;

    @media (max-width: 768px) {
        grid-template-columns: 1fr 1.25fr;
        grid-gap: 0.5rem;
        border: none;
    }

    .content-item {
        display: flex;
        justify-content: space-between;
        padding: 1rem 1rem 1rem 0;

        @media (max-width: 768px) {
            flex-direction: column;
            padding: 0;
        }

        .info {
            display: flex;
            flex-direction: column;
            justify-content: space-around;

            @media (max-width: 768px) {
                h6 {
                    margin-bottom: 0.25rem;
                    &.checkout {
                        font-size: 0.9rem;
                    }
                }
            }

            > span {
                text-transform: uppercase;
                color: #828b9c;
                font-size: 0.75rem;
            }

            .date,
            .peoples {
                display: flex;
                align-items: center;
                font-size: 0.75rem;
                color: #828b9c;

                i {
                    font-size: 1.5rem;
                }

                span {
                    margin-left: 0.5rem;
                }

                figure {
                    img {
                        width: 1.25rem;
                        height: 1.25rem;
                        filter: invert(0.5);
                        @media (max-width: 768px) {
                            width: 1rem;
                            height: 1rem;
                        }
                    }
                }

                &.options {
                    display: none;
                    @media (max-width: 768px) {
                        display: block;
                    }
                    a {
                        color: var(--theme-client-color);
                        display: flex;
                        align-items: center;
                        span {
                            font-size: 1rem;
                            margin-left: 0.2rem;
                        }
                    }
                }

            }

            .box-title {
                display: flex;
                align-items: center;
                justify-content: space-between;

                h6 {
                    margin-bottom: 0 !important;
                    @media (max-width: 768px) {
                        font-size: .9rem;
                    }
                }

                button {
                    display: none;
                    @media (max-width: 768px) {
                        display: block;
                    }
                }


                .mat-icon-button {
                    color: rgba(72, 72, 72, .6)
                }

                .mat-icon {
                    width: 20px;
                    height: 20px;
                    line-height: 20px;
                    font-size: 20px;
                }
            }
        }

        .price-and-actions-container {
            display: flex;
            flex-direction: column;
            justify-content: end;
            align-items: flex-end;

            @media (max-width: 768px) {
                align-items: unset;
            }

            &.cart-delete {
                justify-content: space-between;
            }

            .actions {
                @media (max-width: 768px) {
                    display: flex;
                    justify-content: flex-end;
                    margin-bottom: 0.5rem;
                }

                &.checkout {
                    @media (max-width: 768px) {
                        display: none;
                    }
                    .reserve {
                        color: var(--theme-client-color)
                    }
                }

                i {
                    cursor: pointer;
                    font-size: 1.25rem;
                }

                .mat-icon-button {
                    color: rgba(72, 72, 72, .6)
                }

                .mat-icon {
                    width: 20px;
                    height: 20px;
                    line-height: 20px;
                    font-size: 20px;
                }
            }

            .price {
                display: flex;
                flex-direction: column;
                text-align: end;

                @media (max-width: 768px) {
                    display: block;
                    text-align: left;
                }

                span {
                    font-size: 0.875rem;
                    text-transform: uppercase;

                    @media (max-width: 768px) {
                        margin-right: 0.25rem;
                        font-size: 0.8rem;
                    }

                    &.total {
                        color: #4db911;
                    }
                }
            }
        }
    }
}

@mixin hotels {
    .hotels-content {
        min-height: 334px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        border: solid 1px #dedede;
        border-top: none;

        @media screen and (max-width: 768px) {
            min-height: auto;
        }

        .content {
            color: #484848;

            a {
                &:hover {
                    text-decoration: none;
                }
            }

            .title-container {
                h6 {
                    font-weight: 400;
                    text-transform: uppercase;
                }
                .star {
                    span {
                        font-size: 1rem;
                        color: #e4e4e4;

                        &.active {
                            color: #ffce3d;
                        }
                    }
                }
                .stars-and-location {
                    color: rgba(72, 72, 72, 0.5);
                    font-size: 0.8rem;
                    i {
                        font-size: 1.25rem;
                    }
                }
            }

            .description {
                margin-bottom: 1rem;
                p {
                    font-size: 0.875rem;
                    margin-top: 1rem;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                    margin-bottom: 0;
                }
                span,
                a {
                    font-size: 0.85rem;
                    color: var(--theme-client-color);
                    cursor: pointer;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
        .price {
            font-size: 0.8rem;
            strong {
                color: var(--theme-client-color);
                font-size: 1rem;
                font-weight: 400;
            }
            .payment-message {
                i {
                    font-size: 1rem;
                    margin-left: 0.25rem;
                    color: var(--theme-client-color);
                }
            }
        }
        button {
            width: 100%;
            margin-top: 1rem;
            padding: 0.5rem 1rem;
            border: solid 2px;
            color: var(--theme-client-color);
            background: transparent;
            transition: all 0.3s ease;
            &:hover {
                background: var(--theme-client-color);
                color: #fff;
            }
        }
    }

    &.two-items {
        display: flex;
        @media (max-width: 768px) {
            display: block;
        }

        .hotels-content {
            min-height: auto;

            border: solid 1px #dedede;
            //border-left: none;
            flex: 0 0 50%;
            .box-prices {
                display: flex;
                align-items: center;
                justify-content: space-between;
                @media (max-width: 768px) {
                    display: block;
                }

                a {
                    button {
                        margin-top: 0;
                        @media (max-width: 768px) {
                            margin-top: 1rem;
                        }
                    }
                }
            }
        }
    }
}

@mixin room {
    display: grid;
    grid-template-columns: 33% 67%;
    border: solid 1px #dedede;
    @media only screen and (max-device-width: 800px) {
        display: block;
    }

    .box {
        padding: 1rem;
        display: grid;
        grid-template-columns: 2fr 1.4fr;
        grid-gap: 1rem;

        @media only screen and (max-device-width: 768px) {
            display: flex;
            flex-direction: column;
        }

        .info {
            justify-content: space-between;
            display: flex;
            flex-direction: column;
            max-width: 25rem;

            h6 {
                font-weight: 400;
                text-transform: uppercase;
            }

            p {
                font-size: 0.88rem;

                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
            }
        }

        .prices {
            text-align: center;
            border-left: solid 1px #dedede;
            padding-left: 1rem;

            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 1rem;

            @media only screen and (max-device-width: 768px) {
                border-left: none;
                padding-left: 0;

                flex-direction: column;
                justify-content: center;
                gap: 1rem;
            }

            .box-prices {
                @media only screen and (max-device-width: 768px) {
                    display: flex;
                    align-items: center;
                    flex-direction: row;
                    justify-content: space-evenly;
                    margin-bottom: 0.5rem;
                }
            }

            .nights {
                display: flex;
                align-items: center;
                color: rgba(72, 72, 72, 0.85);
                justify-content: center;
                @media only screen and (max-device-width: 768px) {
                    margin-bottom: 0.5rem;
                }

                .two-prices {
                    flex-direction: column;
                }

                span {
                    font-size: 1.25rem;
                }
            }

            .price {
                color: rgba(72, 72, 72, 0.85);
                margin: 0 0 0.5rem 0;
                @include prices(#4caf50);

                @media only screen and (max-device-width: 768px) {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                }
            }

            .cashback {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 0.5rem;

                @media only screen and (max-device-width: 768px) {
                    flex-direction: row;
                    text-align: left;
                }

                i {
                    font-size: 2.25rem;
                }

                .ame-logo {
                    width: 2.25rem;
                }

                span {
                    font-size: 0.8rem;

                    &.cashback-value {
                        color: #4caf50;
                        font-weight: 500;
                        margin: 0;
                    }
                }
            }
        }

        .restriction {
            display: flex;
            justify-content: center;
            align-items: center;

            border-left: solid 1px #dedede;
            padding-left: 1rem;

            flex-direction: column;
            text-align: center;
            color: #fb7b39;

            @media only screen and (max-device-width: 768px) {
                border-left: none;
                padding-left: 0;
            }

            span {
                font-size: 3rem;
            }
        }

        button {
            @include button;
            background: var(--theme-client-color);
        }
    }
}

@mixin prices($color) {
    .value {
        color: $color;
        font-weight: 400;
        font-size: 1.25rem;
        line-height: 1.25rem;
        span {
            font-size: .9rem;
        }
    }
    .desc-value {
        color: $color;
        font-weight: 300;
        font-size: 0.85rem;

        display: flex;
        align-items: center;
        justify-content: center;

        &.discount {
            flex-direction: column;
            line-height: 1rem;
            margin-top: .5rem;
            color: rgba(72,72,72,.75);
            strong {
                font-size: .9rem;
                font-weight: normal;
                &.getnet {
                    margin-bottom: 2px;
                    font-size: .85rem;
                }
            }
        }

        .popover-content {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;

            span {
                font-size: 1.15rem;
                color: #ff9800;
                margin-left: 5px;
                cursor: pointer;
            }
        }
    }
    .to {
        font-size: 0.85rem;
    }
}

@mixin formFieldConfig {
    width: 100%;
    margin: 0;
    background: transparent;
    border: none;
    color: white;
    font-weight: 400;
    font-size: 0.9rem;
    &::placeholder {
        color: white;
        text-transform: none;
        font-weight: 400;
        font-size: 0.9rem;
    }
}

@mixin backdrop {
    position: fixed;
    background: #00000021;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    z-index: 2;
}

@mixin searchForm {
    background: blue;
    width: 100%;
    color: white;
    position: relative;
    background: var(--theme-client-color);

    &.model-2 {
        position: absolute;
        z-index: 1;
        bottom: 0;




        @media (max-width: 768px) {
            position: relative;
        }
    }

    @media (max-width: 768px) {
        width: auto;
        background: transparent;

        .container {
            padding-left: 0;
            padding-right: 0;
        }
    }

    ul {
        list-style: none;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        justify-content: flex-start;
        padding-inline-start: 0;
        margin-bottom: 0;
        height: 100%;

        @media (max-width: 768px) {
            display: flex;
            flex-direction: column;
            background-color: #ffffff;
            margin: -5rem 1rem 0 1rem;
            box-shadow: 0 0 8px 0 rgb(0 0 0 / 20%);
        }

        li {
            flex: 1 0 25%;
            border-right: solid 1px white;
            display: flex;
            flex-direction: column;
            justify-content: center;

            &:hover {
                cursor: pointer;
            }

            &:focus {
                background: rgb(0, 0, 0, 0.25);
            }

            @media (max-width: 768px) {
                border-right: none;
                border-top: solid 1px #dedede;
                padding: 1rem 1.25rem;
                max-height: 86px;
            }

            &:last-child {
                border: none;
                padding: 0;
            }

            &:first-child {
                border-left: 1px solid white;
                @media (max-width: 768px) {
                    border-left: none;
                }
            }

            label {
                font-weight: 400;
                text-transform: uppercase;
                margin-bottom: 0.75rem;
                font-size: 0.75rem;
                letter-spacing: 2px;
                line-height: 0.75rem;

                &:hover {
                    cursor: pointer;
                }
            }

            .guests-wrapper {
                position: relative;

                .container-guests {
                    padding: 0.9rem;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    position: relative;
                    transition: filter 0.2s ease;

                    @media (max-width: 768px) {
                        padding: 0;
                        background-color: #ffffff !important;
                        color: #484848 !important;
                    }

                    &:hover {
                        filter: brightness(0.9);
                        @media (max-width: 768px) {
                            filter: none;
                        }
                    }
                }
            }

            .form-field {
                display: grid;
                align-items: center;

                .icon {
                    .svg-icon {
                        height: 1.25rem;
                        width: 1.25rem;
                        @media (max-width: 768px) {
                            filter: invert(1);
                        }
                    }
                }

                button {
                    &.search {
                        @include formFieldConfig;
                        background: #4caf50;
                        color: white;
                        padding: 0 3em;
                        text-align: center;
                        transition: all 0.3s;
                        height: 86px;
                        font-size: 1.25rem;
                        border-right: 1px solid #fff;

                        &:hover {
                            filter: brightness(1.2);
                        }

                        @media (max-width: 768px) {
                            height: 76px;
                            margin: 0;
                        }
                    }
                }
            }

            .container-form-guests {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .guests {
                    @include formFieldConfig;
                    align-items: center;
                    display: flex;
                    gap: 2rem;
                    cursor: pointer;

                    .item {
                        display: flex;
                        align-items: center;

                        @media (max-width: 768px) {
                            color: #484848;
                        }

                        span {
                            margin-right: 10px;
                        }

                        .svg-icon {
                            margin-right: 10px;
                            height: 1.25rem;
                            width: 1.25rem;

                            @media (max-width: 768px) {
                                filter: invert(1);
                            }
                        }

                        .quantity-guest,
                        .quantity-accomodation {
                            display: none;
                            margin-left: 0.25rem;

                            @media (max-width: 768px) {
                                display: block;
                                color: #484848;
                            }
                        }
                    }
                }
            }

            .box-guest {
                position: absolute;
                top: 38px;
                left: 0;
                border-radius: 4px;
                width: 360px;
                margin-top: 41px;
                z-index: 1000;
                font-size: 14px;
                background-color: #fff;
                box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 16%),
                    0 2px 8px 0 rgba(0, 0, 0, 12%);
                font-family: 'Rubik';
                display: none;

                @media (max-width: 768px) {
                    top: 0;
                    margin-top: 0;
                    left: 0;
                    height: 100vh;
                    width: 100vw;
                    position: fixed;
                }

                &.active {
                    display: block;
                }

                .arrow {
                    color: #ffffff;
                    position: absolute;
                    top: -25px;
                    display: block;
                    font-size: 3rem;
                    left: 6px;
                }

                .stepper {
                    @media (max-width: 768px) {
                        height: calc(100vh - 70px);
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                    }

                    .inner-stepper {
                        max-height: 400px;
                        overflow: auto;
                        border-bottom: 1px solid #dedede;

                        &::-webkit-scrollbar {
                            width: 4px;
                        }
                        &::-webkit-scrollbar-thumb {
                            background: var(--theme-client-color);
                        }
                        &::-webkit-scrollbar-track {
                            background-color: #f0f0f0;
                        }
                        &::selection {
                            background: var(--theme-client-color);
                            color: #fff;
                        }

                        @media (max-width: 768px) {
                            overflow: auto;
                            max-height: unset;
                            //height: calc(100vh - 70px);
                            border-bottom: none;
                        }

                        .new-accommodation-container {
                            display: flex;
                            justify-content: flex-end;
                            margin-top: 0.5rem;

                            @media (max-width: 768px) {
                                padding-bottom: 4rem;
                                border-bottom: none;
                            }

                            button {
                                background: transparent;
                                color: var(--theme-client-color);
                                padding: 0.5rem 1rem;
                                border: none;
                                border-radius: 4px;
                            }
                        }
                    }
                    @media (max-width: 768px) {
                        mat-divider {
                            display: none;
                        }
                    }

                    h5 {
                        padding: 0.75rem;
                        font-size: 0.75rem;
                        margin-bottom: 0;
                        text-transform: uppercase;
                        color: var(--theme-client-color);
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        span {
                            font-size: 1rem;
                            color: rgba(72, 72, 72, 0.6) !important;
                            cursor: pointer;
                            border-radius: 50px;
                        }
                    }

                    .stepper-item {
                        //border-bottom: 1px solid #dedede;
                    }

                    .step {
                        padding: 0.75rem;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        .box-text {
                            strong {
                                display: block;
                                font-size: 0.9rem;
                                line-height: 1rem;
                                color: #484848;
                                font-weight: normal;
                            }

                            span {
                                display: block;
                                font-size: 0.75rem;
                                color: rgba(72, 72, 72, 0.85);
                            }
                        }

                        .group {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            max-width: 100px;

                            input {
                                width: 100%;
                                text-align: center;
                                font-weight: 700;
                                font-size: 1rem;
                                color: #484848;
                                border: none;
                            }

                            select {
                                min-width: 100px;
                                padding: 0.25rem;
                                color: #484848;
                                border-radius: 4px;
                                background: #fff;
                                margin-right: 5px;
                                &.required {
                                    border: solid 2px var(--theme-client-red);
                                    color: var(--theme-client-red);
                                }
                            }

                            button {
                                height: 1.75rem;
                                width: 1.75rem;
                                border-radius: 1.75rem;
                                line-height: 1.75rem;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                background-color: transparent !important;
                            }
                        }
                    }

                    .footer {
                        padding: 0.75rem;
                        text-align: center;
                        font-weight: 400;
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        @media (max-width: 768px) {
                            position: absolute;
                            bottom: 55px;
                            width: 100%;
                            background: #fff;
                            box-shadow: 0 0 8px 0 rgb(0 0 0 / 20%);
                            height: 70px;
                        }

                        button {
                            background: transparent;
                            color: var(--theme-client-color);
                            padding: 0.5rem 1rem;
                            border: none;
                            border-radius: 4px;

                            &.new-accommodation {
                                background-color: var(--theme-client-color);
                                color: #FFFFFF;

                                @media (max-width: 768px) {
                                    display: none;
                                }
                            }

                            &.close-button {
                                color: var(--theme-client-color);
                            }

                            &.search-button {
                                background: var(--theme-client-color);
                                color: white;
                            }
                        }
                    }
                }
            }

            .backdrop {
                @include backdrop;
            }
        }
    }
}

@mixin containerFooter {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 8px;

    .icon-footer {
        padding: 0 0.75rem;
        margin-right: 0.75rem;
        position: relative;

        i {
            font-size: 1.25rem;
            color: var(--theme-client-color);
            &::before {
                content: "";
                border-right: solid 1px #cdcdcd;
                height: 100%;
                position: absolute;
                right: 0;
            }
        }
    }

    .box {
        position: relative;
        width: 100%;

        p {
            margin-bottom: 0;
            a {
                &:link {
                    text-decoration: none;
                    color: #484848;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    .social-networking {
        display: flex;
        align-items: center;

        a {
            margin: 0 0.35rem;
            transition: all 0.5s;

            button {
                width: 35px;
                height: 35px;
                background: #cecece;
                display: flex;
                align-items: center;
                justify-content: center;
                border: none;
                border-radius: 4px;

                i {
                    color: #484848;
                }
            }

            &:hover {
                text-decoration: none;

                button {
                    &.facebook {
                        background: #0a6ce2;
                    }

                    &.twitter {
                        background: rgb(29, 155, 240);
                    }

                    &.instagram {
                        background: rgb(131, 58, 180);
                        background: linear-gradient(
                            180deg,
                            rgba(131, 58, 180, 1) 0%,
                            rgba(253, 29, 29, 1) 50%,
                            rgba(252, 176, 69, 1) 100%
                        );
                    }

                    &.youtube {
                        background: #c00;
                    }

                    &.tripadvisor {
                        background: #34e0a1;
                    }

                    &.linkedin {
                        background: #0077b7;
                    }

                    &.pinterest{
                        background: #bd081a;
                    }

                    i {
                        color: #ffffff;
                    }
                }
            }
        }
    }
}

@mixin supplements {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    grid-gap: 0.25rem;
    grid-row-gap: 0;

    list-style: none;
    padding-inline-start: 0;

    &.optional {
        flex-direction: column;
        align-items: start;
        margin-bottom: .25rem;
        @media (max-width: 768px) {
            justify-content: space-between;
        }
    }

    li {
        font-size: 0.8rem;
        font-weight: 400;
        margin-bottom: 0;
        color: var(--theme-client-color);

        &.restriction {
            color: #fb7b39;
        }

        &:first-child {
            &:before {
                content: "";
            }
        }

        &:before {
            content: " + ";
        }

        &.items {
            &:before {
                content: "";
            }
            .name {
                margin-right: .5rem;
            }
            display: flex;
            color: var(--theme-client-green);
            align-items: center;
            justify-content: space-between;
        }
    }
}

@mixin contentList {
    list-style: none;
    padding-inline-start: 0;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-gap: 0.25rem;
}

@mixin voucher {
    padding: 0.25rem 1rem;
    width: 100%;
    margin: 0.5rem 0;
    background: transparent;
    border: none;
    border-radius: 2px;
    color: var(--theme-client-color);
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: .5rem;
    &:hover {
        color: white;
        background: var(--theme-client-color);
    }
}

@mixin hightlightPurchase {
    display: inline-block;
    border-bottom: solid 1px #bbbbbb;
    position: relative;
    margin-bottom: 2rem;
    color: #484848;

    @media (max-width: 768px) {
        margin-bottom: 1rem;
    }

    &.width-full {
        width: 100%;
    }

    h2 {
        padding: 0;
        margin: 0;
        font-weight: 400;
        font-size: 1.25rem;
    }

    p {
        padding: 0;
        font-size: 0.875rem;
        margin-bottom: 0.25rem;
    }

    .title-content {
        display: flex;
        align-items: center;

        i {
            color: #fb7e3f;
            margin-left: 0.5rem;
            font-size: 1rem;
        }
    }
}

@mixin containerDays {
    height: 100%;
    display: grid;
    grid-template-rows: repeat(auto-fit, minmax(50px, 1fr));
    row-gap: 0.25rem;

    ul {
        &.days {
            @include contentList;
            li {
                button {
                    text-align: center;
                    color: #484848;
                    font-size: 0.9rem;

                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    transition: all 0.1ms;
                    border: solid 1px transparent;
                    min-height: 60px;

                    @media screen and (min-width: 768px) and (max-width: 992px) {
                        font-size: 0.8rem;
                    }



                    &.restriction {
                        color: var(--theme-client-red);
                        &:disabled {
                            color: var(--theme-client-red) !important;
                        }
                    }

                    &:hover {
                        background: #f5f5f5;
                        border: solid 1px var(--theme-client-color);
                    }

                    &.start-date,
                    &.end-date {
                        color: white;
                        background: var(--theme-client-color);
                        border-radius: 4px;
                        &:disabled {
                            color: white !important;;
                            background: var(--theme-client-color) !important;
                            border-radius: 4px;
                        }
                    }
                    &.entry {
                        background: #f5f5f5;
                        border: solid 1px var(--theme-client-color);
                        &:hover {
                            color: #484848;
                            background: transparent;
                        }
                    }
                    .price,
                    .restriction {
                        font-size: 0.6rem;

                        @media screen and (min-width: 768px) and (max-width: 992px) {
                            font-size: 0.5rem;
                        }

                        &.accommodation {
                            font-size: 0.5rem;

                        }
                    }

                    .restriction {
                        display: none !important;
                        font-size: 0.6rem;

                        @media screen and (min-width: 768px) and (max-width: 992px) {
                            font-size: 0.5rem;
                        }

                        cursor: auto;
                        &:hover {
                            background: transparent;
                        }

                        @media screen and (max-width: 768px) {
                            display: block !important;
                        }

                        &.closed-date {
                            display: block !important;
                        }
                    }
                    &:disabled {
                        opacity: 0.75;
                        background: transparent !important;
                        text-decoration: line-through;
                        color: #ff00007a !important;
                        &:hover {
                            color: #484848;
                            background: transparent;
                            border: none;
                        }
                    }

                    &.disabled-date {
                        opacity: 0;
                        visibility: hidden;
                    }

                    .observation {
                        position: absolute;
                        top: 0;


                        &.search {
                            top: 0;
                            right: 0;

                            background-color: rgba(189, 189, 189, 0.25);
                            width: 1rem;
                            height: 1rem;

                            font-size: 0.625rem;
                            border-radius: 0 0 0 0.25rem;
                        }

                        &.closed {
                            left: 0;

                            background-color: rgba(242, 179, 179, 0.25);
                            color: var(--red);
                            width: 1rem;
                            height: 1rem;

                            font-size: 0.625rem;
                            border-radius: 0 0 0.25rem 0;
                        }

                        &.room {
                            right: 0;

                            padding: 0.125rem;
                            font-size: 0.625rem;
                            border-radius: 0.125rem;
                            line-height: 0.5rem;
                        }
                    }
                }
            }
        }
    }
}

@mixin scroll {
    scroll-margin: 180px 0 0 0;
    @media only screen and (max-device-width: 800px) {
        scroll-margin: 36px 0 0 0;
    }
}

@mixin trigger {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 1rem;
    background-color: #f8f8f8;
    border: 1px solid #ebefef;
    margin-top: 1.5rem;
    margin-bottom: 3rem;

    .icon-container {
        background-color: var(--theme-client-color);
        height: 3rem;
        width: 3rem;
        border-radius: 3rem;
        margin-top: -2.5rem;

        display: flex;
        align-items: center;
        justify-content: center;

        color: #fff;

        i {
            font-size: 2rem;
            color: #fff;
        }

        svg {
            width: 2rem;
            color: #fff;
        }
    }

    h2,
    p {
        margin: 0;
    }

    h2 {
        color: #484848;
        font-size: 1rem;
        margin-top: 1rem;
        font-weight: 400;
    }

    p {
        font-size: 0.75rem;
    }

    .form-content {
        margin-top: 0.5rem;

        input {
            outline: none;
            border: 1px solid #ebefef;
            border-radius: 0.25rem;
            padding: 0.75rem;
            width: 100%;
            font-size: 0.8rem;
            margin-top: 0.5rem;
            color: #484848;
        }

        button {
            outline: none;
            border: none;
            cursor: pointer;
            background-color: var(--theme-client-color);
            color: #fff;
            font-size: 0.875rem;
            font-weight: 400;
            padding: 0.75rem 1.5rem;
            border-radius: 0.25rem;
            margin-top: 0.5rem;
            width: 100%;
            transition: filter 0.2s ease;

            &:hover {
                filter: brightness(1.05);
            }
        }

        .forgot-password {
            text-align: right;

            button {
                background-color: transparent;
                height: unset;
                width: unset;
                padding: 0;
                text-align: end;
                color: #48484899;
                font-size: 0.75rem;
                margin-top: 0.5rem;
                cursor: pointer;
            }
        }
    }

    &.contact {
        .phone {
            margin: 1rem 0;

            a {
                text-decoration: none;
                color: var(--theme-client-color);
                display: flex;
                flex-direction: column;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    &.fidelity {
        p {
            margin-top: 0.5rem;
        }
    }

    &.whatsapp {
        border: 1px solid #95f1b6;
        background-color: #effcef;

        .icon-container {
            background-color: #6cea72;
        }

        a {
            color: #484848;
            font-weight: 400;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}

@mixin totalOrder {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;

    @media (max-width: 768px) {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 1rem;
        position: fixed;
        background: #f1f1f1;
        left: 0;
        z-index: 2;
        padding: 0 0.25rem;
        height: 70px;
        width: calc(100% - 20px);
        border: solid 1px #ccc;
        margin: 0 10px;
        bottom: .5rem !important;
        border-radius: 50px;
    }

    &.checkout {
        @media (max-width: 768px) {
            display: flex;
            width: auto;
            grid-gap: .5rem;
        }
    }

    .icon-cart {
        display: none;
        flex: 0 0 8%;
        background: #e3e3e3;
        height: 100%;
        @media only screen and (max-device-width: 800px) {
            flex: 0 0 auto;
            display: flex;
            align-items: center;
            cursor: pointer;
            background: white;
            border-radius: 50px;
            width: 60px;
            height: 60px;
            border: solid 1px #dedede;
            justify-content: unset;
            padding: .75rem;
        }

        figure {
            position: relative;
            img {
                filter: brightness(0.4);
                width: 2.5rem;
                height: 2.5rem;
                @media only screen and (max-device-width: 800px) {
                    width: 1.6rem;
                    height: 1.6rem;
                }
            }
            .budget {
                background: #f44336;
                padding: 0.15rem;
                color: white;
                border-radius: 25px;
                position: absolute;
                right: -15px;
                top: -5px;
                width: 22px;
                height: 22px;
                font-size: 0.7rem;
                font-weight: 400;
                display: flex;
                align-items: center;
                justify-content: center;
                border: solid 2px white;
                z-index: 1;
                font-family: 'Rubik';
                @media only screen and (max-device-width: 800px) {
                    top: -6px;
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }

    .box-price {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        &.checkout {
            //margin-right: 1.25rem;
            .price-container {
                align-items: start;
            }
        }

        &.end-button {
            @media only screen and (max-device-width: 800px) {
                justify-content: end;
            }
        }

        .price-container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: column;

            span {
                font-size: 0.8rem;
                text-transform: uppercase;

                &.price {
                    color: #4db911;
                    font-size: 1.25rem;
                    @media only screen and (max-device-width: 800px) {
                        font-size: 0.95rem;
                        font-weight: 700;
                    }
                }
            }
        }
    }

    button {
        border: none;
        border-radius: 0.25rem;
        padding: 0.5rem 1rem;
        color: #fff;

        width: 230px;
        height: 3rem;

        background: var(--theme-client-color);

        @media (max-width: 768px) {
            font-size: .9rem;
            padding: .5rem 1.25rem;
            background: var(--theme-client-green);
            width: 60px;
            height: 60px;
            border-radius: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            mat-icon {
                width: 40px;
                height: 40px;
                &.material-icons {
                    font-size: 40px;
                }
            }
        }
    }
}

@mixin buttonPurchase {
    border: none;
    padding: 0.875rem 0.5rem;
    width: 100%;
    color: #FFFFFF;
    border-radius: 0.25rem;
    background: var(--theme-client-color);

    @media (max-width: 768px) {
        margin-top: 1rem;
    }
}

.my-tooltip-min-days {
    background-color: rgb(255, 255, 255) !important;
    color: var(--theme-client-color) !important;
    font-size: 0.625rem !important;
    border: 1px solid var(--theme-client-color);
    white-space: pre-line;
    text-align: center;
}
